import React, { useState, useEffect } from 'react';
import Subheader from '../Subheader';
import { Box, CircularProgress, Stack, Switch } from '@mui/material';
import { styled, alpha } from '@mui/system';
import {
    _getCountries,
    Country,
    _createPost,
    _getPostById,
    _getProductsByCountry,
    _updatePost,
    _getCityByCountryId,
    _getProductDetails,
    _getLanguages
} from 'routes/routes';
import { useSnackbar } from 'notistack';
import {
    formatProductTableSelect,
    formatSelectedProductTable
} from '../TableService';
import {
    DataGrid,
    GridToolbarQuickFilter,
    useGridApiRef
} from '@mui/x-data-grid';
import moment, { Moment } from 'moment';
import useError from 'hooks/useError';
import MultiSelectCheckbox from 'components/Inputs/MultiSelectCheckbox';
import Header from '../Header';
import Top from './Top';
import Bottom from './Bottom';
import useBeforeUnload from 'hooks/useBeforeUnload';
import DraggableTable, { Row } from '../Drag/DraggableTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomModal from './Modal';

interface PostLanguage {
    title: string;
    description: string;
    active_title: string;
    active_description: string;
    language_id: number;
}

interface Product {
    product_id: number;
    price: number;
    sort_index?: number;
}
interface ManageProps {
    onClose: () => void;
    onSave: () => void;
    type: String;
    updateId: number | null;
}
function QuickSearchToolbar() {
    return (
        <div className="filter-product-table">
            <GridToolbarQuickFilter />
        </div>
    );
}
const Manage: React.FC<ManageProps> = ({ onClose, type, onSave, updateId }) => {
    const [open, setOpen] = useState(false);

    const [loaded, setLoaded] = useState<Boolean>(
        type == 'edit' ? false : true
    );
    const [submitloading, setSubmitLoading] = useState<boolean>(false);
    const [checked, setChecked] = React.useState(true);
    const [validTimeCheck, setValidTimeCheck] = React.useState(false);
    const [selectedProductItems, setSelectedProductItems] = useState([]);
    const [activeDate, setActiveDate] = useState<Moment | null>();
    const setError = useError();
    const apiRef = useGridApiRef();
    const selectedApiRef = useGridApiRef();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<any[]>([]);
    const [file, setFile] = useState<File | null>(null);
    const [validFile, setValidFile] = useState<File | null>(null);
    const [languageInputs, setLanguageInputs] = useState<{
        [key: string]: string;
    }>({});
    const [languageInputsError, setLanguageInputsError] = useState<{
        [key: string]: boolean;
    }>({});
    const [imageName, setImageName] = useState<string>('');
    const [validImageName, setValidImageName] = useState<string>('');
    const [countries, setCountries] = useState<Country[]>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [cities, setCities] = useState<any>([]);
    const [selectedCities, setSelectedCities] = useState<any>([]);
    const [flyerData, setFlyerData] = useState<any>([]);
    const [fetchedAll, setFetchedAll] = useState<boolean>(false);
    const [formchanged, setFormChanged] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<Row[]>([]);
    const [productDetails, setProductDetails] = useState([]);
    const [languages, setLanguages] = useState([]);

    useBeforeUnload(formchanged);
    useEffect(() => {
        // if(updateId){
        const fetchOptions = async () => {
            try {
                const product_details = await _getProductDetails();
                setProductDetails(product_details.data);
                const langs = await _getLanguages();
                setLanguages(langs.data);
                const countries = await _getCountries();
                setCountries(countries.data);
                if (updateId) {
                    const response = await _getPostById(updateId);
                    setFlyerData(response.data);
                    await getProductsByCountry(response.data[0].country.id);
                    const cities = await _getCityByCountryId(
                        response.data[0].country.id
                    );
                    setCities(cities.data);
                    setFetchedAll(true);
                }
            } catch (err) {
                setError(err);
            }
        };
        fetchOptions();
        // }
    }, []);

    useEffect(() => {
        if (updateId && countries.length > 0 && flyerData.length > 0) {
            fillinputs(flyerData);
        }
    }, [fetchedAll, countries]);

    const handleClose = () => {
        onClose();
    };
    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => {
        setOpen(false);
    };
    const handleModalSave = async () => {
        const product_details = await _getProductDetails();
        setProductDetails(product_details.data);
    };
    const [selectProducts, setSelectProducts] = useState<any>();
    const fillinputs = (data: {}[]) => {
        let tmpdata: any = {};
        let tmpselectedCities: any = [];
        data.forEach((product: any) => {
            let active_from = moment
                .utc(product.from_date)
                .format('YYYY-MM-DD HH:mm:ss');
            let from_date = moment
                .utc(product.active_from)
                .format('YYYY-MM-DD HH:mm:ss');
            tmpdata['NAME'] = product.name;
            tmpdata['img_path'] = product.img_path;
            tmpdata['valid_img_path'] = product.active_img_path;
            tmpdata['country_id'] = product.country.id;
            tmpdata['active_from'] = product.from_date
                ? moment(active_from)
                      .add(moment().utcOffset(), 'minutes')
                      .format('YYYY-MM-DD HH:mm:ss')
                : null;
            tmpdata['start_date'] = product.active_from
                ? moment(from_date)
                      .add(moment().utcOffset(), 'minutes')
                      .format('YYYY-MM-DD HH:mm:ss')
                : null;
            tmpdata['end_date'] = product.to_date;
            tmpdata['products'] = [];
            tmpdata['cities'] = [];
            tmpdata['sort_index'] = product.sort_index || 0;
            product.cities.forEach((city: any) => {
                tmpdata.cities.push(city.id);
                tmpselectedCities.push(city.short_name);
            });
            product.product_posts.forEach((item: any) => {
                let productobj = item.product;
                productobj['product_prices'] = [
                    {
                        new_price: item.price,
                        price: item.price
                    }
                ];
                productobj['product_detail_category_id'] =
                    item.product_detail_category?.id || null;
                productobj['sort_index'] = item.sort_index;
                // selectedApiRef.current
                tmpdata['products'].push(item.product);
            });
            product.post_languages.forEach((lang: any) => {
                tmpdata[`title-${lang.language.id}`] = lang.title;
                tmpdata[`description-${lang.language.id}`] = lang.description;
                tmpdata[`v-title-${lang.language.id}`] = lang.active_title;
                tmpdata[`v-description-${lang.language.id}`] =
                    lang.active_description;
            });
        });
        if (
            tmpdata.valid_img_path !== null &&
            tmpdata.valid_img_path !== '' &&
            tmpdata.valid_img_path !== undefined
        ) {
            setValidTimeCheck(true);
        }
        const selectedItems = formatSelectedProductTable(tmpdata.products);
        let selectedproductitems: any = [];
        selectedItems.selectedRows.forEach((item: any) => {
            selectedproductitems.push(item.id);
        });
        setSelectedProductItems(selectedproductitems);
        setselectedCountry(tmpdata.country_id);
        setSelectedCities(tmpselectedCities);
        setChecked(tmpdata['sort_index'] == 1 ? true : false);
        const selectedobj = countries.filter(
            (item) => tmpdata.country_id == item.id.toString()
        );
        setselectedCountryObj(selectedobj);
        setSelectProducts(selectedItems.selectedRows);
        setSelectedItems(selectedItems.selectedRows);
        setStartDate(
            tmpdata.start_date ? moment.utc(tmpdata.start_date) : null
        );
        setMinDate(moment.utc(tmpdata.active_from));
        setEndDate(tmpdata.end_date ? moment.utc(tmpdata.end_date) : null);
        setActiveDate(moment.utc(tmpdata.active_from));
        setImageName(tmpdata.img_path);
        setValidImageName(tmpdata.valid_img_path);
        setLanguageInputs(tmpdata);
        setLoaded(true);
    };
    const createParams = () => {
        let active_from = moment(activeDate).format('YYYY-MM-DD HH:mm:ss');
        const params = {
            img_path: imageName,
            active_img_path: validImageName || null,
            name: languageInputs['NAME'],
            from_date: moment(active_from)
                .add(-moment().utcOffset(), 'minutes')
                .format('YYYY-MM-DD HH:mm:ss'),
            to_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            active_from: startDate
                ? moment(startDate)
                      .add(-moment().utcOffset(), 'minutes')
                      .format('YYYY-MM-DD HH:mm:ss')
                : null,
            country_id: parseInt(selectedCountry),
            cities: [] as any,
            post_language: [] as PostLanguage[],
            products: [] as Product[],
            sort_index: checked ? 1 : 0
        };
        let post_language: PostLanguage[] = [];
        selectedCountryObj.forEach((country: any) => {
            country.languages.forEach((language: any) => {
                const languageinfo: PostLanguage = {
                    title: languageInputs[
                        `title-${language.country_language.language_id}`
                    ],
                    description:
                        languageInputs[
                            `description-${language.country_language.language_id}`
                        ],
                    active_title:
                        languageInputs[
                            `v-title-${language.country_language.language_id}`
                        ],
                    active_description:
                        languageInputs[
                            `v-description-${language.country_language.language_id}`
                        ],
                    language_id: language.country_language.language_id
                };
                post_language.push(languageinfo);
            });
        });
        params.post_language = post_language;
        let products: Product[] = [];
        selectedProducts.forEach((item: any, index: number) => {
            let product: any = {
                product_id: item.productId,
                price: item.price,
                sort_index: index,
                product_detail_category_id:
                    item.product_detail_category_id != '-1'
                        ? item.product_detail_category_id
                        : null
            };
            products.push(product);
        });
        const filteredCities = cities.filter((item: any) =>
            selectedCities.includes(item.short_name)
        );
        if (cities.length !== filteredCities.length) {
            filteredCities.forEach((city: any) => {
                params.cities.push(city.id);
            });
        }

        params.products = products;
        return params;
    };

    const checkParams = (params: any) => {
        if (params.name === '' || params.name == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`NAME`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`NAME`]: false
            }));
        }
        let flyernamecheck = true;
        params.post_language.forEach((flyer: any) => {
            if (flyer.title === '' || flyer.title === undefined) {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`title-${flyer.language_id}`]: true
                }));
                flyernamecheck = false;
            } else {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`title-${flyer.language_id}`]: false
                }));
            }
            if (flyer.description === '' || flyer.description === undefined) {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`description-${flyer.language_id}`]: true
                }));
                flyernamecheck = false;
            } else {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`description-${flyer.language_id}`]: false
                }));
            }
        });
        if (
            params.from_date === '' ||
            params.from_date == undefined ||
            params.from_date == 'Invalid date'
        ) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`fromdate`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`fromdate`]: false
            }));
        }
        if (
            params.active_from === '' ||
            params.active_from == undefined ||
            params.active_from == 'Invalid date'
        ) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`valid-date`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`valid-date`]: false
            }));
        }
        if (validTimeCheck) {
            let flyernamecheck = true;
            params.post_language.forEach((flyer: any) => {
                if (
                    flyer.active_title === '' ||
                    flyer.active_title === undefined
                ) {
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`v-title-${flyer.language_id}`]: true
                    }));
                    flyernamecheck = false;
                } else {
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`v-title-${flyer.language_id}`]: false
                    }));
                }
                if (
                    flyer.active_description === '' ||
                    flyer.active_description === undefined
                ) {
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`v-description-${flyer.language_id}`]: true
                    }));
                    flyernamecheck = false;
                } else {
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`v-description-${flyer.language_id}`]: false
                    }));
                }
            });
            if (!flyernamecheck) {
                return {
                    validation: false,
                    msg: 'Flyer Name field is Required'
                };
            }
        }
        if (params.name === '' || params.name == undefined) {
            return {
                validation: false,
                msg: 'Name input is Required'
            };
        }
        if (
            params.from_date === '' ||
            params.from_date == undefined ||
            params.from_date == 'Invalid date'
        ) {
            return {
                validation: false,
                msg: 'Start Date field is Required'
            };
        }
        if (
            params.country_id === '' ||
            params.country_id == undefined ||
            Number.isNaN(params.country_id)
        ) {
            return {
                validation: false,
                msg: 'Please select a country'
            };
        }

        if (!flyernamecheck) {
            return {
                validation: false,
                msg: 'Flyer Name field is Required'
            };
        }
        if (params.products.length == 0) {
            return {
                validation: false,
                msg: 'Please Select Products to show in your flyer'
            };
        }
        if (validTimeCheck) {
            let flyernamecheck = true;
            if (!flyernamecheck) {
                return {
                    validation: false,
                    msg: 'Flyer Name field is Required'
                };
            }
        }
        return {
            validation: true,
            msg: ''
        };
    };
    const handleSubmit = async () => {
        const params = createParams();
        const validation = checkParams(params);
        setSubmitLoading(true);
        if (type == 'Edit') {
            if (validation.validation) {
                try {
                    await _updatePost(params, updateId);
                    setSubmitLoading(false);
                    enqueueSnackbar('Flyer Updated ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setSubmitLoading(false);
                    setError(err);
                }
            } else {
                setSubmitLoading(false);
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        } else {
            if (validation.validation) {
                try {
                    await _createPost(params);
                    setSubmitLoading(false);
                    enqueueSnackbar('Flyer Created ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setSubmitLoading(false);
                    setError(err);
                }
            } else {
                setSubmitLoading(false);
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        }

        onSave(); // Call the onClose callback to close the component
    };

    const handleInput2Change = (value: string, type: string) => {
        const trimmedValue = value.trimStart();
        setFormChanged(true);
        setLanguageInputs((prevState) => ({
            ...prevState,
            [`${type}`]: trimmedValue
        }));
    };
    const [selectedCountry, setselectedCountry] = useState<string>('');
    const [selectedCountryObj, setselectedCountryObj] = useState<any>([]);
    const getProductsByCountry = async (id: number) => {
        const response = await _getProductsByCountry(id);
        setData(response.data);
    };
    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        if (loaded) {
            const selectedobj = countries.filter(
                (item) => event.target.value == item.id.toString()
            );
            setFormChanged(true);
            setselectedCountryObj(selectedobj);
            getProductsByCountry(selectedobj[0].id);

            setselectedCountry(event.target.value);
            const cities = await _getCityByCountryId(selectedobj[0].id);
            setCities(cities.data);
            setSelectedItems([]);
        }
    };
    const selectoptions = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                value={selectedCountry}
                onChange={handleSelectChange}
            >
                <option key={-1}>Select Country</option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                        {country.name}
                    </option>
                ))}
            </select>
        </div>
    );
    const { formattedColumns, formattedRows } = formatProductTableSelect(
        data,
        false
    );
    const handleRowSelection = (selection: any) => {
        if (loaded) {
            const newdata = JSON.parse(JSON.stringify(data));
            const newselection: any = [];
            setFormChanged(true);
            newdata.forEach((item: any) => {
                if (selection.includes(item.id)) {
                    if (selectedItems.length) {
                        let new_price = selectedItems.filter((product: any) =>
                            selection.includes(product.id)
                        );
                        new_price.forEach((old_pr: any) => {
                            if (item.id === old_pr.id) {
                                item['sort_index'] = old_pr.sort_index;
                                item.product_prices[0].price = old_pr.price;
                            }
                        });
                    }
                    if (item.sort_index == undefined) {
                        item['sort_index'] = selectedItems.length;
                    }
                    newselection.push(item);
                }
            });
            const current_selectedItems = formatSelectedProductTable(
                newselection,
                true,
                { selectedApiRef }
            );
            setSelectedProductItems(selection);
            setSelectedItems(current_selectedItems.selectedRows);
        }
    };
    const [minDate, setMinDate] = useState<Moment | null>(null);
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);

    const handleStartDateChange = (date: Moment | null) => {
        setStartDate(date);
        setFormChanged(true);
    };
    const handleDateTimeChange = (date: Moment | null) => {
        setFormChanged(true);
        setMinDate(date);
        setActiveDate(date);
    };
    const handleEndDateChange = (date: Moment | null) => {
        setFormChanged(true);
        setEndDate(date);
    };
    const handleSelectedCitiesChange: React.Dispatch<
        React.SetStateAction<string[]>
    > = (newSelectedCities) => {
        setFormChanged(true);
        setSelectedCities(newSelectedCities);
    };
    const multiSelectComponent = cities.length ? (
        <MultiSelectCheckbox
            options={cities}
            selectedOptions={selectedCities}
            setSelectedOptions={handleSelectedCitiesChange}
            selectall={true}
            title="Select Cities"
        />
    ) : (
        ''
    );
    const CustomSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#72BF45',
            '&:hover': {
                backgroundColor: alpha('#72BF45', 0.2)
            }
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#72BF45'
        }
    }));
    const label = { inputProps: { 'aria-label': 'Show on Top' } };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    const handleValidTimeCheck = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setValidTimeCheck(event.target.checked);
    };
    const handleFileUpload = (uploadedFile: File, image_name: string) => {
        setFile(uploadedFile);
        setImageName(image_name);
    };
    const handleValidFileUpload = (uploadedFile: File, image_name: string) => {
        setValidFile(uploadedFile);
        setValidImageName(image_name);
    };

    const handleRowsUpdate = (updatedRows: Row[]) => {
        const new_rows: Row[] = [];
        updatedRows.forEach((item: any, index: number) => {
            item.sort_index = index;
            new_rows.push(item);
        });
        setSelectedProducts(new_rows);
    };
    return (
        <div className="h-full">
            <Header
                type={type + ' Flyer'}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                loading={submitloading}
            />
            <Subheader
                multiSelectComponent={selectoptions}
                secondMultiSelect={multiSelectComponent}
                description="Select Countries you want your product to be displayed"
            />
            {selectedCountry !== '' ? (
                <div
                    className="flex manage-body pb-[50px]"
                    style={{ height: 'calc(100% - 200px);' }}
                >
                    <CustomModal
                        open={open}
                        onClose={handleModalClose}
                        onSave={handleModalSave}
                        languages={languages}
                        title="Add Product Details"
                    />
                    <div className="body-content flex flex-col pl-[50px]">
                        <div className="flex h-full">
                            <div className="flex flex-col left-side hide-scrollbar">
                                <div className="header-text py-8 text-[#969696] font-bold text-lg ">
                                    <span>
                                        Flyer information post time and valid
                                        time
                                    </span>
                                </div>
                                <div className="product manage-first flex flex-initial flex-col pr-[70px] gap-4">
                                    <Top
                                        languageInputs={languageInputs}
                                        handleInput2Change={handleInput2Change}
                                        label={label}
                                        checked={checked}
                                        handleSwitchChange={handleSwitchChange}
                                        handleFileUpload={handleFileUpload}
                                        imageName={imageName}
                                        minDate={minDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        handleStartDateChange={
                                            handleStartDateChange
                                        }
                                        handleEndDateChange={
                                            handleEndDateChange
                                        }
                                        handleDateTimeChange={
                                            handleDateTimeChange
                                        }
                                        activeDate={activeDate}
                                        selectedCountryObj={selectedCountryObj}
                                        languageInputsError={
                                            languageInputsError
                                        }
                                        type={type}
                                    />
                                    <div className="flex flex-col border-underline"></div>
                                    <div className="header-text py-8 text-[#969696] font-bold text-lg flex justify-between items-center">
                                        <span>Valid time information</span>
                                        <div className="show-on-top">
                                            <CustomSwitch
                                                checked={validTimeCheck}
                                                onChange={handleValidTimeCheck}
                                            />
                                        </div>
                                    </div>
                                    {validTimeCheck && (
                                        <Bottom
                                            languageInputs={languageInputs}
                                            handleInput2Change={
                                                handleInput2Change
                                            }
                                            handleFileUpload={
                                                handleValidFileUpload
                                            }
                                            imageName={validImageName}
                                            selectedCountryObj={
                                                selectedCountryObj
                                            }
                                            languageInputsError={
                                                languageInputsError
                                            }
                                            minDate={minDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            handleStartDateChange={
                                                handleStartDateChange
                                            }
                                            handleEndDateChange={
                                                handleEndDateChange
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col right-side hide-scrollbar">
                                <div className="header-text py-8 text-[#969696] font-bold text-lg pl-[70px]">
                                    <span>Select product for the flyer</span>
                                </div>
                                <div className="body-content flex flex-col pl-[70px]">
                                    <div className="flex gap-4 flex-wrap">
                                        <div
                                            className="flex flex-col"
                                            style={{
                                                width: 'calc(100% - 46px)'
                                            }}
                                        >
                                            <div className="table-container select-products">
                                                <Box
                                                    sx={{
                                                        height: 400 // Adjust height as necessary
                                                    }}
                                                >
                                                    <DataGrid
                                                        rows={formattedRows}
                                                        columns={
                                                            formattedColumns
                                                        }
                                                        apiRef={apiRef}
                                                        checkboxSelection
                                                        pageSizeOptions={[]}
                                                        onRowSelectionModelChange={
                                                            handleRowSelection
                                                        }
                                                        slots={{
                                                            toolbar:
                                                                QuickSearchToolbar
                                                        }}
                                                        sx={{
                                                            '& .MuiDataGrid-columnHeaders':
                                                                {
                                                                    color: '#ADADAD',
                                                                    fontSize:
                                                                        '15px',
                                                                    minHeight:
                                                                        '70px',
                                                                    fontFamily:
                                                                        'sans-serif',
                                                                    fontWeight:
                                                                        '600'
                                                                },
                                                            '& .MuiDataGrid-virtualScroller':
                                                                {
                                                                    color: '#969696',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600'
                                                                }
                                                        }}
                                                        rowSelectionModel={
                                                            selectedProductItems
                                                        }
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                        <div
                                            className="flex flex-col"
                                            style={{
                                                width: 'calc(100% - 46px)'
                                            }}
                                        >
                                            <div className="table-container select-products">
                                                <Box>
                                                    {/* Custom Title Header */}
                                                    <div className="flyer-table-header">
                                                        Products on the flyer
                                                        <div
                                                            className="add-category-icon"
                                                            onClick={
                                                                handleModalOpen
                                                            }
                                                        >
                                                            <AddCircleIcon />
                                                        </div>
                                                    </div>

                                                    {/* DataGrid with hidden header */}

                                                    <Box
                                                        sx={{
                                                            '& .MuiDataGrid-columnHeaders':
                                                                {
                                                                    display:
                                                                        'none'
                                                                },
                                                            height: 400, // Adjust height as necessary
                                                            overflow: 'auto'
                                                        }}
                                                    >
                                                        <DraggableTable
                                                            rows={selectedItems}
                                                            productDetails={
                                                                productDetails
                                                            }
                                                            onRowsUpdate={
                                                                handleRowsUpdate
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : type !== 'Edit' ? (
                <div className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                    Please select country to countinue!
                </div>
            ) : (
                <div className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                    <CircularProgress></CircularProgress>
                </div>
            )}
        </div>
    );
};

export default Manage;
