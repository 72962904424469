import React, { useState, useEffect } from 'react';
import Subheader from '../Subheader';
// import Input from "../../Inputs/Input";
import {
    _getProductById,
    _updateProduct,
    _getCountries,
    Country,
    _createProduct
} from 'routes/routes';
import { CircularProgress, TextField } from '@mui/material';
import { styled } from '@mui/system';
import MultiSelectCheckbox from 'components/Inputs/MultiSelectCheckbox';
import { products } from 'components/languages/product';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import useError from 'hooks/useError';
import FileUpload from 'components/Inputs/FileUpload';
import Header from '../Header';
import useBeforeUnload from 'hooks/useBeforeUnload';
const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 38px; // Adjust the height as needed
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eeeeee;
        color: #969696;
    }
    & .MuiInputBase-root:hover,
    & .MuiInputBase-root.Mui-focused {
        border: none !important;
        border-width: 0px !important;
    }
`;
interface ProductInfo {
    title: string;
    description: string;
    language_id: number;
}

interface CountryInfo {
    country_id: number;
    price: number;
    product_language: ProductInfo[];
}
interface ManageProps {
    onClose: () => void;
    onSave: () => void;
    type: string;
    updateId: number | null;
}
const Manage: React.FC<ManageProps> = ({ onClose, type, onSave, updateId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const setError = useError();
    const [file, setFile] = useState<File | null>(null);
    const [file2, setFile2] = useState<File | null>(null);
    const [file3, setFile3] = useState<File | null>(null);
    const [submitloading, setSubmitLoading] = useState<boolean>(false);
    const [languageInputs, setLanguageInputs] = useState<{
        [key: string]: string;
    }>({});
    const [languageInputsError, setLanguageInputsError] = useState<{
        [key: string]: boolean;
    }>({});
    const [imageName, setImageName] = useState<string>('');
    const [imageName2, setImageName2] = useState<string>('');
    const [imageName3, setImageName3] = useState<string>('');
    const [countries, setCountries] = useState<Country[]>([]);
    const [countriesToDraw, setCountriesToDraw] = useState<Country[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
    const [selectedCountry, setselectedCountry] = useState<string>('');
    const [formchanged, setFormChanged] = useState<boolean>(false);

    useBeforeUnload(formchanged);
    useEffect(() => {
        if (updateId) {
            const fetchOptions = async () => {
                try {
                    const response = await _getProductById(updateId);
                    fillinputs(response.data);
                } catch (err) {
                    setError(err);
                }
            };
            fetchOptions();
        }
    }, []);
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await _getCountries();
                setCountries(response.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchOptions();
    }, []);
    useEffect(() => {
        const filteredItems = countries.filter(
            (item: any) =>
                selectedCountries.includes(item.id) ||
                selectedCountries.includes(item.id.toString())
        );
        setCountriesToDraw(filteredItems);
    }, [selectedCountries, countries]);
    const handleSelectedCountriesChange: React.Dispatch<
        React.SetStateAction<string[]>
    > = (newSelectedCountries) => {
        setSelectedCountries(newSelectedCountries);
    };
    const handleClose = () => {
        setFile(null);
        setLanguageInputs({});
        setImageName('');
        setImageName2('');
        setImageName3('');
        setCountries([]);
        setCountriesToDraw([]);
        setSelectedCountries([]);
        onClose();
    };
    const fillinputs = (data: {}[]) => {
        let tmpdata: any = {};
        let tmpcountries: string[] = [];
        let tmpcountry: any = '';
        data.forEach((product: any) => {
            tmpdata['ID'] = product.external_id;
            tmpdata['NAME'] = product.name;
            tmpdata['img_path'] = product.img_path;
            tmpdata['img_path2'] = product.img_path_2;
            tmpdata['img_path3'] = product.img_path_3;
            product.product_prices.forEach((item: any) => {
                tmpcountry = item.country.id;
                tmpcountries.push(item.country.id);
                item.country.product_languages.forEach((i: any) => {
                    tmpdata[`name-${item.country.id}-${i.language.id}`] =
                        i.title;
                    tmpdata[`description-${item.country.id}-${i.language.id}`] =
                        i.description;
                    tmpdata[`price-${item.country.id}`] = item.price;
                });
            });
        });
        // setImageName(tmpdata.img_path)

        setImageName(tmpdata.img_path);
        setImageName2(tmpdata.img_path2);
        setImageName3(tmpdata.img_path3);
        if (tmpdata.img_path3 != null) {
            setImageCount(3);
        }
        if (tmpdata.img_path3 == null && tmpdata.img_path2 != null) {
            setImageCount(2);
        }
        setselectedCountry(tmpcountry);
        setSelectedCountries(tmpcountries);
        setLanguageInputs(tmpdata);
    };
    const createParams = () => {
        const image2 =
            imageName2 != '' && imageName2 != null ? imageName2 : null;
        const image3 =
            imageName3 != '' && imageName3 != null ? imageName3 : null;
        const params = {
            img_path: imageName,
            img_path_2: image2,
            img_path_3: image3,
            name: languageInputs['NAME'],
            external_id: languageInputs['ID'],
            available_countries: [] as CountryInfo[]
        };

        const availableCountries: CountryInfo[] = [];

        countriesToDraw.forEach((country) => {
            const countryInfo: CountryInfo = {
                country_id: country.id,
                price: 0,
                product_language: []
            };

            country.languages.forEach((language) => {
                const productInfo: ProductInfo = {
                    title:
                        languageInputs[
                            `name-${country.id}-${language.country_language.language_id}`
                        ] || '',
                    description:
                        languageInputs[
                            `description-${country.id}-${language.country_language.language_id}`
                        ] || '',
                    language_id: language.country_language.language_id
                };

                countryInfo.price =
                    Number(languageInputs[`price-${country.id}`]) || 0;
                countryInfo.product_language.push(productInfo);
            });

            availableCountries.push(countryInfo);
        });

        params.available_countries = availableCountries;
        return params;
    };
    const checkParams = (params: any) => {
        // const params = createParams();

        if (params.name === '' || params.name == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`NAME`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`NAME`]: false
            }));
        }
        if (params.external_id === '' || params.external_id == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`ID`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`ID`]: false
            }));
        }
        let pricecheck = true;
        let titlecheck = true;
        params.available_countries.forEach((prices: any) => {
            if (
                prices.price === 0 ||
                prices.price === '' ||
                prices.price === undefined
            ) {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`price-${prices.country_id}`]: true
                }));
                pricecheck = false;
            } else {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`price-${prices.country_id}`]: false
                }));
            }
            prices.product_language.forEach((product: any) => {
                if (product.title === '' || product.title === undefined) {
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`name-${product.language_id}`]: true
                    }));
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`description-${product.language_id}`]: true
                    }));
                    titlecheck = false;
                } else {
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`name-${product.language_id}`]: false
                    }));
                    setLanguageInputsError((prevState) => ({
                        ...prevState,
                        [`false-${product.language_id}`]: true
                    }));
                }
            });
        });
        if (params.name === '' || params.name === undefined) {
            return {
                validation: false,
                msg: 'Name input is Required'
            };
        }
        if (params.external_id === '' || params.external_id == undefined) {
            return {
                validation: false,
                msg: 'Product id field is Required'
            };
        }
        if (params.img_path === '' || params.img_path === undefined) {
            return {
                validation: false,
                msg: 'Please upload an image'
            };
        }

        if (!pricecheck) {
            return {
                validation: false,
                msg: 'Product Price field is Required'
            };
        }
        if (!titlecheck) {
            return {
                validation: false,
                msg: 'Product Name field is Required'
            };
        }
        if (params.available_countries.length == 0) {
            return {
                validation: false,
                msg: 'Please select Countries'
            };
        }
        return {
            validation: true,
            msg: ''
        };
    };
    const handleSubmit = async () => {
        const params = createParams();
        const validation = checkParams(params);
        setSubmitLoading(true);
        if (type == 'Edit') {
            if (validation.validation) {
                try {
                    await _updateProduct(params, updateId);
                    setSubmitLoading(false);
                    enqueueSnackbar('Product Updated ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setSubmitLoading(false);
                    setError(err);
                }
            } else {
                setSubmitLoading(false);
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        } else {
            if (validation.validation) {
                try {
                    await _createProduct(params);
                    setSubmitLoading(false);
                    enqueueSnackbar('Product Created ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setSubmitLoading(false);
                    setError(err);
                }
            } else {
                setSubmitLoading(false);
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        }

        onSave(); // Call the onClose callback to close the component
    };
    const handleInputChange = (
        countryId: number,
        languageId: number,
        value: string,
        type: string
    ) => {
        const trimmedValue = value.trimStart();
        setFormChanged(true);
        setLanguageInputs((prevState) => ({
            ...prevState,
            [`${type}-${countryId}-${languageId}`]: trimmedValue
        }));
    };
    const handleInput2Change = (
        value: string,
        type: string,
        datatype: string
    ) => {
        let trimmedValue = value.trimStart();
        setFormChanged(true);
        if (datatype === 'number') {
            trimmedValue = trimmedValue.replace(/[^\d.]/g, '');
            trimmedValue = trimmedValue.replace(/(\..*?)\..*/g, '$1');
        }
        setLanguageInputs((prevState) => ({
            ...prevState,
            [`${type}`]: trimmedValue
        }));
    };
    const multiSelectComponent = (
        <MultiSelectCheckbox
            options={countries}
            selectedOptions={selectedCountries}
            setSelectedOptions={handleSelectedCountriesChange}
            title="Select Countries"
        />
    );
    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setFormChanged(true);
        setselectedCountry(event.target.value);
        setSelectedCountries([event.target.value]);
    };
    // type == 'Edit';
    const selectoptions = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                value={selectedCountry}
                onChange={handleSelectChange}
                disabled={type == 'Edit' ? true : false}
            >
                <option key={-1}>Select Country</option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                        {country.name}
                    </option>
                ))}
            </select>
        </div>
    );
    const handleFileUpload = (uploadedFile: File, image_name: string) => {
        setFormChanged(true);
        setFile(uploadedFile);
        setImageName(image_name);
    };
    const handleFileUpload2 = (uploadedFile: File, image_name: string) => {
        setFormChanged(true);
        setFile2(uploadedFile);
        setImageName2(image_name);
    };
    const handleFileUpload3 = (uploadedFile: File, image_name: string) => {
        setFormChanged(true);
        setFile3(uploadedFile);
        setImageName3(image_name);
    };
    const handleNewImage = () => {
        setImageCount(imagecount + 1);
    };

    const [imagecount, setImageCount] = useState<number>(1);
    return (
        <div className="h-full">
            <Header
                type={type + ' Product'}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
            />
            <Subheader
                multiSelectComponent={selectoptions}
                description="Select Countries you want your product to be displayed"
            />
            {selectedCountry !== '' ? (
                <div
                    className="flex manage-body pb-[50px]"
                    style={{ height: 'calc(100% - 200px);' }}
                >
                    <div className="body-content flex flex-col px-[50px]">
                        <div className="flex h-full">
                            <div className="flex flex-col left-side hide-scrollbar">
                                <div className="header-text py-8 text-[#969696] font-bold text-lg ">
                                    <span>
                                        Product Information In Selected
                                        languages
                                    </span>
                                </div>
                                <div className="product manage-first flex flex-initial flex-col pr-[70px] gap-4">
                                    <FileUpload
                                        key={1}
                                        onFileUpload={handleFileUpload}
                                        img_name={imageName}
                                        label={`Image 1`}
                                    />
                                    {imagecount >= 2 && (
                                        <FileUpload
                                            key={2}
                                            onFileUpload={handleFileUpload2}
                                            img_name={imageName2}
                                            label={`Image 2`}
                                        />
                                    )}
                                    {imagecount >= 3 && (
                                        <FileUpload
                                            key={3}
                                            onFileUpload={handleFileUpload3}
                                            img_name={imageName3}
                                            label={`Image 3`}
                                        />
                                    )}
                                    {imagecount < 3 && (
                                        <button
                                            onClick={handleNewImage}
                                            className="px-4 py-2 bg-[#72BF45] w-[161px] text-white custom-input flex justify-center items-center"
                                        >
                                            Add
                                        </button>
                                    )}
                                    <div className="product-name product flex flex-col gap-2">
                                        <label className="">Product Id</label>
                                        <CustomTextField
                                            type="text"
                                            required
                                            error={languageInputsError[`ID`]}
                                            placeholder="ID"
                                            value={languageInputs[`ID`] || ''}
                                            name="ID"
                                            autoComplete="product-id"
                                            onChange={(e) =>
                                                handleInput2Change(
                                                    e.target.value,
                                                    'ID',
                                                    'text'
                                                )
                                            }
                                            style={{ background: '#F9FDF7' }}
                                        />
                                        <label className="">Product Name</label>
                                        <CustomTextField
                                            type="text"
                                            required
                                            placeholder="NAME"
                                            name="name" // Standard name attribute for autocomplete
                                            autoComplete="name" // Use standard value
                                            error={languageInputsError[`NAME`]}
                                            value={languageInputs[`NAME`] || ''}
                                            onChange={(e) =>
                                                handleInput2Change(
                                                    e.target.value,
                                                    'NAME',
                                                    'text'
                                                )
                                            }
                                            style={{ background: '#F9FDF7' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex right-side hide-scrollbar flex-auto pl-[70px] flex-wrap gap-16 ">
                                {countriesToDraw.map((country) => (
                                    <div className="flex-col">
                                        <div className="header-text py-8 text-[#969696] font-bold text-lg">
                                            <span>{country.name}</span>
                                        </div>
                                        <div className="flex flex-col gap-2 min-w-[300px]">
                                            <div className="product-name product flex flex-col gap-2">
                                                <label className="">
                                                    Product Name
                                                </label>

                                                {country.languages.map(
                                                    (language) => (
                                                        <>
                                                            <CustomTextField
                                                                type="text"
                                                                required
                                                                error={
                                                                    languageInputsError[
                                                                        `name-${language.country_language.language_id}`
                                                                    ]
                                                                }
                                                                placeholder={
                                                                    products
                                                                        .name[
                                                                        language.short_name as keyof typeof products.name
                                                                    ]
                                                                }
                                                                value={
                                                                    languageInputs[
                                                                        `name-${country.id}-${language.country_language.language_id}`
                                                                    ] || ''
                                                                }
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        country.id,
                                                                        language
                                                                            .country_language
                                                                            .language_id,
                                                                        e.target
                                                                            .value,
                                                                        'name'
                                                                    )
                                                                }
                                                                style={{
                                                                    background:
                                                                        '#F9FDF7'
                                                                }}
                                                                InputProps={{
                                                                    endAdornment:
                                                                        (
                                                                            <InputAdornment position="end">
                                                                                <div
                                                                                    className={`flag-${language.short_name}`}
                                                                                ></div>
                                                                            </InputAdornment>
                                                                        )
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                )}
                                            </div>
                                            <div className="product-price product flex flex-col gap-2">
                                                <label>Product Price</label>
                                                <CustomTextField
                                                    type="text"
                                                    required
                                                    placeholder={`Price`}
                                                    error={
                                                        languageInputsError[
                                                            `price-${country.id}`
                                                        ]
                                                    }
                                                    value={
                                                        languageInputs[
                                                            `price-${country.id}`
                                                        ] || ''
                                                    }
                                                    onChange={(e) =>
                                                        handleInput2Change(
                                                            e.target.value,
                                                            `price-${country.id}`,
                                                            'number'
                                                        )
                                                    }
                                                    style={{
                                                        background: '#F9FDF7'
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <div
                                                                    className={``}
                                                                >
                                                                    {
                                                                        country
                                                                            .currency
                                                                            .short_name
                                                                    }
                                                                </div>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="product-description product flex flex-col gap-2">
                                                <label>
                                                    Product Description
                                                </label>
                                                {country.languages.map(
                                                    (language) => (
                                                        <>
                                                            <TextField
                                                                type="text"
                                                                required
                                                                placeholder={
                                                                    products
                                                                        .description[
                                                                        language.short_name as keyof typeof products.description
                                                                    ]
                                                                }
                                                                error={
                                                                    languageInputsError[
                                                                        `description-${language.country_language.language_id}`
                                                                    ]
                                                                }
                                                                value={
                                                                    languageInputs[
                                                                        `description-${country.id}-${language.country_language.language_id}`
                                                                    ] || ''
                                                                }
                                                                multiline
                                                                rows={3}
                                                                style={{
                                                                    background:
                                                                        '#F9FDF7'
                                                                }}
                                                                inputProps={{
                                                                    className:
                                                                        'text-area'
                                                                }}
                                                                // sx={{
                                                                //     background: '#F9FDF7',
                                                                //     borderRadius: 10, // Set border radius here
                                                                //     '& .MuiInputBase-input': {
                                                                //         // Additional styles for input element
                                                                //         padding: '10px' // Example: Add padding to input
                                                                //     }
                                                                // }}
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        country.id,
                                                                        language
                                                                            .country_language
                                                                            .language_id,
                                                                        e.target
                                                                            .value,
                                                                        'description'
                                                                    )
                                                                }
                                                                InputProps={{
                                                                    endAdornment:
                                                                        (
                                                                            <InputAdornment position="end">
                                                                                <div
                                                                                    className={`flag-${language.short_name}`}
                                                                                ></div>
                                                                            </InputAdornment>
                                                                        )
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : type !== 'Edit' ? (
                <div className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                    Please select country to countinue!
                </div>
            ) : (
                <div className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                    <CircularProgress></CircularProgress>
                </div>
            )}
        </div>
    );
};

export default Manage;
