// DraggableTable.tsx
import React, { FC, useState, useEffect } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd';
import { Box, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

export interface Row {
    imageUrl: string;
    _name: string;
    _desc: string;
    active_from: string;
    active_to: string;
    sort_index?: number;
    [key: string]: any;
}
function sortArrayOfObjectsByProperty(
    arr: any,
    property: string,
    type?: string
) {
    if (type == 'asc') {
        return arr.sort((a: any, b: any) => {
            if (a[property] > b[property]) return 1;
            if (a[property] < b[property]) return -1;
            return 0;
        });
    }
    return arr.sort((a: any, b: any) => {
        if (a[property] < b[property]) return 1;
        if (a[property] > b[property]) return -1;
        return 0;
    });
}

interface DraggableTableProps {
    rows: Row[];
    onRowsUpdate: (updatedRows: Row[]) => void;
}

const RowContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#fff',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: '#f5f5f5'
    }
}));

const Cell = styled(Box)(({ theme }) => ({
    color: '#ADADAD',
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
}));

const DragFlyers: FC<DraggableTableProps> = ({ rows, onRowsUpdate }) => {
    const [tableRows, setTableRows] = useState<Row[]>(rows);
    useEffect(() => {
        setTableRows(rows);
        onRowsUpdate(rows);
    }, [rows]);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const updatedRows = Array.from(tableRows);
        const [movedRow] = updatedRows.splice(result.source.index, 1);
        updatedRows.splice(result.destination.index, 0, movedRow);

        setTableRows(updatedRows);
        onRowsUpdate(updatedRows);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <Box
                        component={Paper}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        p={0}
                    >
                        {tableRows.length === 0 ? (
                            <Box py={2}>
                                <Typography align="center">
                                    No data available
                                </Typography>
                            </Box>
                        ) : (
                            tableRows.map((row, index) => (
                                <Draggable
                                    key={row._name}
                                    draggableId={row._name}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <RowContainer
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps
                                                    .style,
                                                backgroundColor:
                                                    snapshot.isDragging
                                                        ? '#f0f0f0'
                                                        : 'white'
                                            }}
                                        >
                                            <Cell>
                                                <img
                                                    src={row.imageUrl}
                                                    alt={row.name}
                                                    style={{
                                                        maxWidth: 120,
                                                        flex: 1
                                                    }}
                                                />
                                            </Cell>
                                            <Cell
                                                style={{
                                                    minWidth: '100px',
                                                    flex: 1
                                                }}
                                            >
                                                {row._name}
                                            </Cell>
                                            <Cell
                                                style={{
                                                    minWidth: '100px',
                                                    flex: 1
                                                }}
                                            >
                                                {row._desc}
                                            </Cell>
                                            <Cell
                                                style={{
                                                    minWidth: '100px',
                                                    flex: 1
                                                }}
                                            >
                                                {row.active_from}
                                            </Cell>
                                            <Cell
                                                style={{
                                                    minWidth: '100px',
                                                    flex: 1
                                                }}
                                            >
                                                {row.active_to}
                                            </Cell>
                                        </RowContainer>
                                    )}
                                </Draggable>
                            ))
                        )}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragFlyers;
