import { Box, CircularProgress } from '@mui/material';
import useError from 'hooks/useError';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    Country,
    TUpdateFlyerSorting,
    _getCountries,
    _getPosts,
    _updateFlyerOrder
} from 'routes/routes';
import DragFlyers, { Row } from '../Drag/DragFlyers';
import _config from 'config';
import { enqueueSnackbar } from 'notistack';
interface OrderProps {
    selectedCountry: string;
}
const OrderFlyers: React.FC<OrderProps> = ({ selectedCountry }) => {
    const setError = useError();
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredData, setFilteredData] = useState<Row[]>([]);
    const [sortedRows, setSortedRows] = useState<
        { id: number; index: number }[]
    >([]);
    const handleRowsUpdate = (updatedRows: Row[]) => {
        const new_rows: { id: number; index: number }[] = [];
        updatedRows.forEach((item: any, index: number) => {
            // item.sort_index = index;
            const new_item = {
                id: item.id,
                index: index
            };
            new_rows.push(new_item);
        });
        setSortedRows(new_rows);
    };
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await _getPosts();
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError(err);
            }
        };
        fetchOptions();
    }, []);
    useEffect(() => {
        const filtered_data = data;
        if (selectedCountry != '-1') {
            const new_data: Row[] = filtered_data
                .filter((item) => item.country.id == selectedCountry)
                .map((item) => ({
                    ...item,
                    imageUrl: `${_config.s3_url}/${item.img_path}`,
                    _name: item.name,
                    _desc:
                        item.post_languages[0].language_id === 1
                            ? item.post_languages[0].title
                            : item.post_languages[1].title,
                    active_from: moment
                        .utc(item.from_date)
                        .format('DD-MM-YYYY'),
                    active_to: item.to_date
                        ? moment.utc(item.to_date).format('DD-MM-YYYY')
                        : 'N/A',
                    sort_index: item.sort_index
                }));
            setFilteredData(new_data);
        } else {
            setFilteredData([]);
        }
    }, [selectedCountry, data]);
    if (selectedCountry !== '-1' && loading) {
        return (
            <Box className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                <CircularProgress></CircularProgress>
            </Box>
        );
    }
    const handleSubmit = async () => {
        try {
            const params: TUpdateFlyerSorting = {
                posts: sortedRows
            };
            await _updateFlyerOrder(params);
            //  setSubmitLoading(false);
            enqueueSnackbar('Flyer Order Updated ', { variant: 'success' });
        } catch (err) {
            //  setSubmitLoading(false);
            setError(err);
        }
    };
    return (
        <Box className="h-full">
            {selectedCountry !== '-1' ? (
                <Box>
                    <Box className="w-full h-[55px] flex justify-end p-[9px] bg-[#f0f0f0] rounded-t-[10px]">
                        <button
                            onClick={handleSubmit}
                            className={`px-4 py-2 w-[161px] text-white custom-input flex justify-center items-center bg-[#72BF45]`}
                        >
                            Save
                        </button>
                    </Box>
                    <DragFlyers
                        rows={filteredData}
                        onRowsUpdate={handleRowsUpdate}
                    />
                </Box>
            ) : (
                <Box className="flex manage-body f24 font-bold text-[#707070] justify-center items-center ">
                    Please select country to countinue!
                </Box>
            )}
        </Box>
    );
};

export default OrderFlyers;
