// Subheader.tsx
import React from 'react';
interface Props {
    multiSelectComponent?: React.ReactNode;
    secondMultiSelect?: React.ReactNode;
    button?: React.ReactNode;
    description?: string;
}
const Subheader: React.FC<Props> = ({
    multiSelectComponent,
    description,
    secondMultiSelect,
    button
}) => {
    return (
        <div className="bg-[#F0F0F0] p-2 h-[80px] px-[50px] flex items-center justify-between">
            <h2 className="text-lg font-semibold sub-description">
                {description}
            </h2>
            <div className="flex gap-2">
                {multiSelectComponent}
                {secondMultiSelect}
                {button}
            </div>
        </div>
    );
};

export default Subheader;
